import React, { useEffect, useState } from "react";
import Fuse from "fuse.js";
import "./style.css";
import SearchInput from "react-search-autocomplete/dist/components/SearchInput";
import { Images } from "./assets/Images/Images";
import { debounce } from "react-search-autocomplete/dist/utils/utils";
import { ResponseCard } from "./components/ResponseCard";
import { trackEvent } from "./utils";

const defaultPlaceHolderText = "Search for doubts, symptoms, & more";

const SearchEngine = (props) => {
  const [QnABank, setQnABank] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchString, setSearchString] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [inputBlurred, setInputBlurred] = useState(true);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showSubmitQues, setShowSubmitQues] = useState(false);
  const [showThanksMsg, setShowThanksMsg] = useState(false);
  const [selectedQuesData, setSelectedQuesData] = useState(null);

  useEffect(() => {
    trackEvent("initialized_pia");
  }, []);

  useEffect(() => {
    fetch("./QnABank.json")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setQnABank(data);
      })
      .catch((err) => {
        setQnABank([]);
      });
  }, []);

  useEffect(() => {
    if (searchString.length > 0 && QnABank) {
      const options = {
        includeScore: true,
        shouldSort: true,
        ignoreLocation: true,
        minMatchCharLength: 3,
        threshold: 0.1,
        keys: ["question", "answer"],
      };
      const fuse = new Fuse(QnABank, options);
      const result = fuse.search(searchString);
      setSearchResults(result);
    } else {
      setSearchResults([]);
    }
  }, [searchString, QnABank]);

  useEffect(() => {
    if(searchResults?.length >= 1) {
      setShowSearchResults(true);
    } else {
      setShowSearchResults(false);
    }
  },[searchResults]);

  useEffect(() => {
    if(searchResults?.length < 1 && searchString?.length >= 3) {
      setShowSubmitQues(true);
    } else {
      setShowSubmitQues(false);
    }
  },[searchResults, searchString]);

  useEffect(() => {
    document.querySelector('[data-test="search-input"]').addEventListener('focusout', ()=>{
      setInputBlurred(true);
    });
  }, []);

  useEffect(() => {
    if(showThanksMsg) {
      setTimeout(() => {
        setShowThanksMsg(false);
      }, 4000);
    }
  }, [showThanksMsg]);

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  const onQuestionSelect = (quesData) => {
    const faqsAsked = searchResults
      .filter((entity) => {
        return entity.item.id !== quesData.item.id;
      })
      .slice(0, 3);
    if (faqsAsked.length < 3) {
      const itr = 3 - faqsAsked.length;
      for (let index = 0; index < itr; index++) {
        faqsAsked.push({ item: QnABank[getRandomInt(QnABank.length)] });
      }
    }
    const selectedQuesDataHolder = {
      ...quesData.item,
      top_faqs_asked: faqsAsked,
    };
    setSelectedQuesData(selectedQuesDataHolder);
    setSearchText(quesData?.item?.question);
    setShowSearchResults(false);
  };

  return (
    <div style={{
      height: '100%',
    }}>
      <div
        style={{
          display: "flex",
          justifyContent: selectedQuesData ? "space-between": "flex-end",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 18,
          marginRight: 18,
          marginLeft: 18,
        }}
      >
        {selectedQuesData && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              setSearchText("");
              setSearchString("");
              setSearchResults([]);
              setSelectedQuesData(null);
              setShowSearchResults(false);
              setShowSubmitQues(false);
              setShowThanksMsg(false);
            }}
          >
            <img
              src={Images.BACK_ICON}
              alt="logo"
              style={{ width: "5%", marginRight: 8 }}
            />
            <span>Back to search</span>
          </div>
        )}
        <img
          src={Images.SEARCH_ENGINE_LOGO}
          alt="logo"
          style={{ height: (inputBlurred && !selectedQuesData) ? 23 : 16 }}
        />
      </div>
      <div
        style={{
          padding: 18,
          marginTop: (inputBlurred && !selectedQuesData) ? 38 : 20,
          borderTopLeftRadius: 36,
          borderTopRightRadius: 36,
          boxShadow: (inputBlurred && !selectedQuesData) ? '' : '0px -10px 9px rgba(0, 0, 0, 0.06)',
          display: (inputBlurred && !selectedQuesData) ? 'flex' : 'inherit',
          flexDirection: 'column',
          justifyContent: 'center',
          height: (inputBlurred && !selectedQuesData) ? window.innerHeight / 1.6 : '100%',
        }}
      >
        {inputBlurred && !selectedQuesData && (<div
          style={{
            textAlign: "left",
            lineHeight: "36px",
            fontSize: 24,
            fontWeight: 700,
            marginBottom: 12,
          }}
        >
          Ask Something
        </div>)}
        <div
          style={{
          }}
        >
          <div
            style={{
              backgroundColor: "#F5F5F5",
              minHeight: 57,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 10,
              marginBottom: 12,
              border: (inputBlurred && !selectedQuesData) ? "1.5px solid rgba(115, 115, 115, 0.8)" : "",
            }}
          >
            <SearchInput
              searchString={searchText}
              placeholder={defaultPlaceHolderText}
              onFocus={() => {
                setTimeout(function() {window.scrollTo(0, 0);},100)
                setInputBlurred(false);
                trackEvent("tapped_search_bar");
                setSearchString(searchText);
                if (searchText?.length > 0) {
                  setShowThanksMsg(false);
                }
                if(searchResults?.length >= 1) {
                  setShowSearchResults(true);
                } else {
                  setShowSearchResults(false);
                }
              }}
              setSearchString={(e) => {
                const target = e.target;
                const keyword = target.value;
                setSearchText(keyword);
                setShowThanksMsg(false);
                debounce(() => {
                  trackEvent("typing_in_search_bar", {
                    searched: keyword,
                  });
                  setSearchString(keyword);
                }, 200)();
              }}
            />
          </div>
        </div>
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            padding: (showSearchResults || showSubmitQues || showThanksMsg) ? 8 : 0,
            borderRadius: 8,
            borderWidth: 0,
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
            marginBottom: 20,
          }}
        >
          {
            showSearchResults && (
              <div
              style={{
                width: '100%'
              }}
              >
                <div
                  style={{
                    textAlign: "left",
                    lineHeight: "18px",
                    fontSize: 12,
                    fontWeight: 700,
                    color: "#737373",
                  }}
                >
                  Please select one
                </div>
                <div
                  style={{
                    overflowY: "scroll",
                    maxHeight: window.innerHeight / 2
                  }}
                >
                  {searchResults.map((item) => (
                    <div
                      style={{
                        backgroundColor: "#E5E5E5",
                        minHeight: 34,
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 12,
                        paddingRight: 12,
                        borderRadius: 6,
                        marginTop: 8,
                      }}
                      onMouseDown={() => {
                        onQuestionSelect(item);
                        trackEvent("search_result_select", {
                          ques: item?.item?.id,
                        });
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          lineHeight: "18px",
                          fontSize: 12,
                          fontWeight: 600,
                          color: "#262626",
                          marginTop: 8,
                          marginBottom: 8,
                        }}
                      >
                        {item?.item?.question}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )
          }
          {
            showSubmitQues && (
              <div
              style={{
                width: '100%'
              }}
              >
                <div
                  style={{
                    textAlign: "left",
                    lineHeight: "18px",
                    fontSize: 12,
                    fontWeight: 700,
                    color: "#737373",
                  }}
                >
                  Question not found. Please submit the question to get an answer in a day!
                </div>
                <div
                  style={{
                    backgroundColor: "#E5E5E5",
                    alignItems: "center",
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 8,
                    paddingBottom: 8,
                    borderRadius: 6,
                    flexWrap: "wrap",
                    wordWrap: 'break-word',
                    textAlign: "left",
                    lineHeight: "18px",
                    fontSize: 12,
                    fontWeight: 600,
                    color: "#262626",
                    marginTop: 16,
                    marginBottom: 16,
                  }}
                  onClick={() => {
                  }}
                >
                  "{searchText}"
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 8,
                    paddingTop: 8,
                    paddingBottom: 8,
                  }}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      color: "#737373",
                      border: "1.5px solid rgba(115, 115, 115, 0.8)",
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 8,
                      paddingBottom: 8,
                      borderRadius: 4,
                      marginRight: 16,
                    }}
                    onClick={() => {
                      var view;
                      if (selectedQuesData) {
                        setSearchText(selectedQuesData.question);
                        view = "results_screen";
                      } else {
                        setSearchText("");
                        setSearchString("");
                        setSearchResults([]);
                        setSelectedQuesData(null);
                        view = "home_screen";
                      }
                      trackEvent("ques_not_submitted", {
                        ques: searchText,
                        screen: view,
                      });
                      setShowSubmitQues(false);
                    }}
                  >
                    Not now
                  </div>
                  <div
                    style={{
                      fontWeight: "600",
                      color: "white",
                      backgroundColor: "#0F867D",
                      border: "1px solid rgba(40, 137, 56, 1)",
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 8,
                      paddingBottom: 8,
                      borderRadius: 4,
                    }}
                    onClick={() => {
                      var view;
                      if (selectedQuesData) {
                        setSearchText(selectedQuesData.question);
                        view = "results_screen";
                      } else {
                        setSearchText("");
                        setSearchString("");
                        setSearchResults([]);
                        setSelectedQuesData(null);
                        view = "home_screen";
                      }
                      setShowThanksMsg(true);
                      trackEvent("ques_submitted", {
                        ques: searchText,
                        screen: view,
                      });
                      setShowSubmitQues(false);
                    }}
                  >
                    Submit
                  </div>
                </div>
              </div>
            )
          }
          {
            showThanksMsg && (
              <div
              style={{
                width: '100%'
              }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: "column",
                    backgroundColor: "#E5E5E5",
                    alignItems: "center",
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingTop: 8,
                    paddingBottom: 8,
                    borderRadius: 6,
                  }}
                >
                  <img
                    src={Images.QUES_SUBMITTED}
                    alt="logo"
                    style={{ width: "25%", marginBottom: 4 }}
                  />
                  <span
                    style={{
                      textAlign: "center",
                      lineHeight: "18px",
                      fontSize: 12,
                      fontWeight: 600,
                      color: "#525252",
                    }}
                  >
                    Thank you for helping us help you!<br />You will get your answer in 24 hrs
                  </span>
                </div>
              </div>
            )
          }
        </div>
        {selectedQuesData && (
            <div style={{ }}>
              <ResponseCard
                data={selectedQuesData}
                onPressFaq={(dat) => {
                  onQuestionSelect(dat);
                  setShowThanksMsg(false);
                  window.scrollTo(0, 0);
                  trackEvent("top_faq_select", {
                    ques: dat?.item?.id,
                  });
                }}
              />
            </div>
          )}
        {!selectedQuesData && inputBlurred && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              position: "absolute",
              bottom: 0,
              right: 0,
              left: 0,
            }}
          >
            <img
              src={Images.BRANDING_LOGO}
              alt="logo"
              style={{ height: 27, marginBottom: 12 }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

SearchEngine.propTypes = {};

export default SearchEngine;

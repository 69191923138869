import { analytics } from "./firebase";
import mixpanel from 'mixpanel-browser';
import { logEvent } from "firebase/analytics";

export const trackEvent = (eventName, params) => {
  if (params) {
    logEvent(analytics, eventName.substring(0, 39), params);
    mixpanel.track(eventName.substring(0, 39), params);
  } else {
    logEvent(analytics, eventName.substring(0, 39));
    mixpanel.track(eventName.substring(0, 39));
  }
};
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBQaNGLiKO9ffvpfEtVuygJU0irBFmdpoQ",
  authDomain: "pregnancy-search-engine.firebaseapp.com",
  projectId: "pregnancy-search-engine",
  storageBucket: "pregnancy-search-engine.appspot.com",
  messagingSenderId: "461480679470",
  appId: "1:461480679470:web:1145b141c36dca7c46a7bf",
  measurementId: "G-LPTL7CDBXM",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
//  logEvent(analytics, "goal_completion", { title: qna.title });
import React from "react";
import "./style.css";
import { Images } from "../../assets/Images/Images";
import { trackEvent } from "../../utils";

export const ResponseCard = (props) => {
  const {
    data: { answer, view_count_str, trimester, specialty, top_faqs_asked },
    onPressFaq,
  } = props;
  return (
    <div>
      {/* First Card */}
      <div class="card" style={{ backgroundColor: "#FBF1F1" }}>
        <img
          src={Images.DOC_2}
          alt="doctor"
          style={{
            width: "20%",
            position: "absolute",
            bottom: 0,
            left: 10,
          }}
        />
        <div class="container">
          <div
            style={{
              borderRadius: 15,
              backgroundColor: "white",
              color: "white",
              display: "flex",
              float: "left",
              width: "max-content",
              marginTop: 10,
              paddingLeft: 6,
              paddingTop: 5,
              paddingBottom: 5,
              paddingRight: 22,
            }}
          >
            <span
              style={{
                position: "absolute",
                top: 21,
                left: 37,
                fontSize: 10,
                color: "#004766",
                fontWeight: 700,
              }}
            >
              Verified by Ob/Gyn
            </span>
            <img
              src={Images.APPROVED}
              alt="approved"
              style={{
                width: "15%",
                float: "left",
              }}
            />
          </div>
          <p
            style={{
              marginTop: 55,
              marginBottom: 35,
              textAlign: "left",
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            {answer}
          </p>
        </div>
      </div>
      {/* Second Card */}
      <div
        class="card"
        style={{ display: "inline-block", textAlign: "left", padding: 12 }}
      >
        <img
          src={Images.TELEGRAM_LOGO}
          alt="tg"
          style={{
            width: "6%",
            position: "absolute",
            top: 13,
            left: 12,
          }}
        />
        <span style={{ fontSize: 16 }}>
          <b
            style={{
              marginLeft: 27,
            }}
          >
            Talk to other women
          </b>
        </span>

        <div
          class="percent"
          style={{
            marginBottom: 25,
            padding: 12,
          }}
        >
          <p
            style={{
              fontSize: 14,
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontWeight: 500,
              }}
            >
              {view_count_str}
            </span>
            {"  "}
            women asked this question in their{"  "}
            <span style={{ fontWeight: 600 }}>{trimester}</span>.
          </p>
          {specialty && (
            <p
              style={{
                fontSize: 14,
              }}
            >
              {"  "}
              This question is often linked to{"  "}
              <span style={{ fontWeight: 600 }}>{specialty}</span>.
            </p>
          )}
        </div>
        <div
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          <span
            style={{
              position: "absolute",
              bottom: 13,
              right: 16,
              borderRadius: 4,
              backgroundColor: "#0A9A90",
              color: "white",
              width: "max-content",
              alignItems: "center",
              fontSize: 13,
              paddingRight: 31,
              paddingLeft: 31,
              paddingTop: 8,
              paddingBottom: 8,
              fontWeight: 600,
            }}
            onClick={() => {
              window.open("https://t.me/+iRq07oWdqAoyZjA1", "_blank");
              trackEvent("telegram_link_click");
            }}
          >
            Go to Telegram
          </span>
        </div>
      </div>
      {/* Third Card */}
      {top_faqs_asked?.length > 0 && (
        <div class="card" style={{ display: "flex", textAlign: "left" }}>
          <div class="percent">
            <p
              style={{
                fontSize: 16,
                verticalAlign: "center",
              }}
            >
              <b>Women also ask</b>
            </p>
            {top_faqs_asked.map((item) => (
              <div
                style={{
                  backgroundColor: "#F5F5F5",
                  width: "100%",
                  borderRadius: 10,
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingRight: 5,
                  paddingLeft: 10,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                  }}
                  onClick={() => {
                    onPressFaq(item);
                  }}
                >
                  {item.item.question}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Fourth Card */}
      <div
        class="card"
        style={{
          display: "flex",
          textAlign: "left",
          marginTop: 10,
          padding: 10,
        }}
      >
        <img
          src={Images.DOC_1}
          alt="doc"
          style={{
            height: "100px",
            alignContent: "flex-start",
            marginBottom: 10,
            marginTop: 10,
          }}
        />
        <div class="percent">
          <p
            style={{
              fontSize: 16,
              margin: 3,
            }}
          >
            <b>Call a doctor</b>
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 25,
            }}
          >
            <img
              src={Images.CALENDER}
              alt="calender"
              style={{
                width: 14,
                height: 14,
                marginRight: 7,
              }}
            />
            <span
              style={{
                fontSize: 14,
              }}
            >
              Everyday, 10:00 am - 9:00 pm
            </span>
          </div>
          <div
            style={{
              position: "absolute",
              top: 10,
              right: 0,
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 20,
              backgroundColor: "#FDE1E1",
              color: "#0F7670",
              textAlign: "center",
              width: "max-content",
              height: "max-content",
              paddingTop: 7,
              paddingBottom: 7,
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 10,
            }}
          >
            <span style={{ fontSize: 13, fontWeight: 600 }}>₹49 Only</span>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 13,
              right: 18,
              borderRadius: 4,
              backgroundColor: "#0A9A90",
              color: "white",
              textAlign: "center",
              width: "max-content",
              height: "max-content",
              paddingTop: 7,
              paddingBottom: 7,
              paddingLeft: 20,
              paddingRight: 20,
              marginTop: 10,
            }}
            onClick={() => {
              window.open("https://rzp.io/l/1NKpOyz6", "_blank");
              trackEvent("book_dr_app_click");
            }}
          >
            <span style={{ fontSize: 13, fontWeight: 600 }}>
              Book Appointment
            </span>
          </div>
        </div>
      </div>
      {/* Fifth Card */}
      <div
        class="card"
        style={{
          display: "flex",
          textAlign: "center",
          marginTop: 10,
          backgroundColor: "#FFF7E2",
          height: "max-content",
        }}
      >
        <div
          class="percent"
          style={{
            height: 70,
          }}
        >
          <img
            src={Images.EMOJIS}
            alt="emojis"
            style={{
              position: "absolute",
              top: 16,
              left: 18,
              marginRight: 10,
              width: "35%",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: 25,
              right: 18,
              borderRadius: 4,
              backgroundColor: "#0A9A90",
              color: "white",
              width: "max-content",
              height: "max-content",
              padding: 8,
              paddingRight: 18,
              paddingLeft: 18,
              marginLeft: 10,
              alignItems: "center",
              fontSize: 13,
              fontWeight: 600,
            }}
            onClick={() => {
              window.open("https://forms.gle/cFvCcbUkiA7Roi3q6", "_blank");
              trackEvent("feedback_button_click");
            }}
          >
            Leave your feedback
          </div>
        </div>
      </div>
    </div>
  );
};

export const Images = {
  SEARCH_ENGINE_LOGO: require("./ask_pia_logo_1.png"),
  BRANDING_LOGO: require("./branding_logo.png"),
  QUES_SUBMITTED: require("./ques_submitted.png"),
  BACK_ICON: require("./back_icon.png"),
  APPROVED: require("./approved.png"),
  DOC_1: require("./doctor_1.png"),
  DOC_2: require("./doctor_2.png"),
  PW: require("./pw.png"),
  PW_QUES: require("./pw_ques.png"),
  TELEGRAM_LOGO: require("./telegram.png"),
  EMOJIS: require("./emojis.png"),
  CALENDER: require("./calender.png"),
};
